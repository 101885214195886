import React, { useState } from 'react';
import { PageProps, navigate } from 'gatsby';
import { TranslationProvider } from '@/src/contexts/translation.context';
import { Helmet } from 'react-helmet';
import { TranslationService } from '@/src/services/translation.service';
import { FormConfig } from '@/src/models/form.model';

import AnswerSecurityQuestionsForm from './answer-security-questions-form';
import FindAccountForm from './find-account';
import Header from '@/src/components/header/header';
import * as styles from './forgot-password.module.scss';
import { useApi } from '@/src/contexts/api.context';

export default function ForgotPasswordPage(pageProps: PageProps) {
  const apiService = useApi();
  const [translation] = useState(new TranslationService(pageProps.pageContext));
  const [findAccountForm, setFindAccountForm] = useState(null);
  const [findAccountLoading, setFindAccountLoading] = useState(null);
  const [findAccountFormErrorMessage, setFindAccountFormErrorMessage] = useState(null);
  const [securityQuestionsForm, setSecurityQuestionsForm] = useState(null);
  const [securityQuestions, setSecurityQuestions] = useState(null);
  const [submitNewPasswordErrorMessage, setSubmitNewPasswordErrorMessage] = useState(null);
  const [isNextStep, setIsNextStep] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [finished, setFinished] = useState(false);

  const nextStep = (form: FormConfig) => {
    setFindAccountForm(form);
    setFindAccountLoading(true);
    findAccount(form);
  };

  const back = (form: FormConfig) => {
    setSecurityQuestionsForm(form);
    setIsNextStep(false);
  };

  const findAccount = async (form: FormConfig) => {
    try {
      setFindAccountFormErrorMessage(null);

      const result = await apiService.findAccount({
        username: form.username.value,
        email: form.email.value
      });

      setSecurityQuestions(result.securityQuestions);
      setIsNextStep(true);
    } catch(err) {
      setFindAccountFormErrorMessage(translation.translate('forgot_password.find_account_error'));
    } finally {
      setFindAccountLoading(false);
    }
  };

  const submitNewPassword = async (form: FormConfig) => {
    setSubmitNewPasswordErrorMessage(null);
    setSubmitting(true);

    try {
      await apiService.submitNewPassword({
        username: findAccountForm.username.value,
        email: findAccountForm.email.value,
        securityQuestions: securityQuestions.map((question, i) => {
          return {
            ...question,
            answer: form['securityAnswer' + i].value
          };
        }),
        password: form.password.value,
      });

      setFinished(true);
    } catch(err) {
      setSubmitNewPasswordErrorMessage(translation.translate('forgot_password.submit_new_password_error'));
    } finally {
      setSubmitting(false);
    }
  };

  const goToLogin = (event: React.MouseEvent) => {
    event.preventDefault();
    navigate(`${translation.appLinkPrefix}/login`);
  };

  return (
    <TranslationProvider value={translation}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{translation.translate('forgot_password.title')}</title>
        <meta name="description" content={translation.translate('forgot_password.description')} />
        <meta http-equiv="content-language" content={translation.lang} />
        <html className="unauthenticated"></html>
      </Helmet>

      <Header unauthenticated={true} pageContext={pageProps.pageContext}></Header>

      <div className="unauthenticated__wrapper">
        <div className="unauthenticated__inner">
          {
            !isNextStep ?
              <FindAccountForm {...pageProps}
                nextStep={nextStep}
                findAccountForm={findAccountForm}
                loading={findAccountLoading}
                error={findAccountFormErrorMessage} /> :
              !finished ?
                <AnswerSecurityQuestionsForm {...pageProps}
                  back={back}
                  securityQuestionsForm={securityQuestionsForm}
                  securityQuestions={securityQuestions}
                  submitNewPassword={submitNewPassword}
                  submitting={submitting}
                  error={submitNewPasswordErrorMessage} /> :
                <div className={styles.finished}>
                  <p className={styles.finishedText}>
                    {translation.translate('forgot_password.finished_text')}
                  </p>
                  <a onClick={goToLogin} href="#">{translation.translate('forgot_password.log_in_text')}</a>
                </div>
          }
        </div>
      </div>

      <div className="decoration-circle-wrapper">
        <div className="decoration-circle">
        </div>
      </div>

    </TranslationProvider>
  );
}