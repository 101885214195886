import React, { useEffect, useState } from 'react';
import { createFormField, minLength, passwordMatchValidator, requiredValidator } from '@/src/models/form.model';
import { FormError } from '@/src/components/form-error/form-error';
import { TranslationService } from '../../../services/translation.service';
import { LargeButton } from '@/src/components/button/button';
import { useForm } from '@/src/hooks/form.hook';

import Spinner from '@/src/components/spinner/spinner';
import * as styles from './answer-security-questions-form.module.scss';

export default function AnswerSecurityQuestionsForm({ pageContext, submitNewPassword, back, securityQuestionsForm, securityQuestions, submitting, error }) {
  const defaultSecurityQuestionsForm = {
    password: {
      ...createFormField(
        (onInputChange, translation, value, isValid, error, key) => {
          return <>
            <input
              key={key}
              id="password"
              name="password"
              placeholder={translation.translate('forgot_password.new_password')}
              type="password"
              autoComplete="off"
              value={value ? value : ''}
              onChange={onInputChange} />
            <FormError valid={isValid} message={error}></FormError>
          </>;
        }
      ),
      validationRules: [
        requiredValidator(),
        minLength(8),
      ]
    },
    repeatPassword: {
      ...createFormField(
        (onInputChange, translation, value, isValid, error, key) => {
          return <>
            <input
              key={key}
              id="repeatPassword"
              name="repeatPassword"
              placeholder={translation.translate('forgot_password.new_password_repeat')}
              type="password"
              autoComplete="off"
              value={value ? value : ''}
              onChange={onInputChange} />
            <FormError valid={isValid} message={error}></FormError>
          </>;
        }
      ),
      validationRules: [
        requiredValidator(),
        passwordMatchValidator('password')
      ]
    }
  };

  for(let i = 0; i < securityQuestions.length; i++) {
    defaultSecurityQuestionsForm['securityAnswer' + i] = {
      ...createFormField(
        (onInputChange, translation, value, isValid, error, key) => {
          return <>
            <input
              key={key}
              id={'securityAnswer' + i}
              name={'securityAnswer' + i}
              placeholder={translation.translate('forgot_password.security_answer_placeholder')}
              type="text"
              value={value ? value : ''}
              onChange={onInputChange} />
            <FormError valid={isValid} message={error}></FormError>
          </>;
        }
      ),
      validationRules: [
        requiredValidator()
      ]
    };
  }

  securityQuestionsForm = securityQuestionsForm || defaultSecurityQuestionsForm;
  const [translation] = useState(new TranslationService(pageContext));
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const { renderInput, isFormValid, touchForm, form } = useForm(translation, securityQuestionsForm);

  const goBack = (event: React.MouseEvent) => {
    event.preventDefault();
    back(form);
  };

  const submit = (event: React.FormEvent) => {
    event.preventDefault();
    touchForm();
    setSubmitAttempted(true);
    return false;
  };

  useEffect(() => {
    if(submitAttempted) {
      if(isFormValid()) {
        submitNewPassword(form);
      }

      setSubmitAttempted(false);
    }
  }, [submitAttempted]);

  return (
    <form className="form" onSubmit={submit} autoComplete="off">

      <h1>{translation.translate('forgot_password.answer_security_questions')}</h1>

      <p className="auth__description">{translation.translate('forgot_password.answer_security_questions_info')}</p>

      {
        securityQuestions.map((question, i) => {
          return <div className={styles.questionGroup} key={i}>
            <p>{question.question}</p>

            <div className="form__group">
              <div className="form__field">
                {renderInput('securityAnswer' + i)}
              </div>
            </div>
          </div>;
        })
      }

      <div className="divider"></div>

      <div className="form__group">
        <div className="form__field">
          {renderInput('password')}
        </div>
      </div>

      <div className="form__group">
        <div className="form__field">
          {renderInput('repeatPassword')}
        </div>
      </div>

      {error ?
        <div className="form__error-message" dangerouslySetInnerHTML={{ __html: error }}></div> : null}

      <div className="form__group form__group--submit">
        <LargeButton type="submit" loading={submitting}>
          <span>{translation.translate('forgot_password.reset_password')}</span>
        </LargeButton>
      </div>

      <div className="form__group auth-link">
        <a onClick={goBack} href="#">{translation.translate('authentication.back_to_first_step')}</a>
      </div>
    </form>
  );
}