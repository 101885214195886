import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { createFormField, emailValidator, requiredValidator } from '@/src/models/form.model';
import { FormError } from '@/src/components/form-error/form-error';
import { TranslationService } from '../../../services/translation.service';
import InputDescription from '@/src/components/input-description/input-description';
import { useForm } from '@/src/hooks/form.hook';

import { LargeButton } from '@/src/components/button/button';
import Spinner from '@/src/components/spinner/spinner';

const defaultFindAccountForm = {
  username: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <input
            key={key}
            id="username"
            name="username"
            placeholder={translation.translate('authentication.username')}
            type="text"
            autoComplete="off"
            value={value ? value : ''}
            onChange={onInputChange} />
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      requiredValidator()
    ]
  },
  email: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <input
            key={key}
            id="email"
            name="email"
            placeholder={translation.translate('authentication.email')}
            type="text"
            autoComplete="off"
            value={value ? value : ''}
            onChange={onInputChange} />
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      requiredValidator(),
      emailValidator()
    ]
  }
};

export default function FindAccountForm({ pageContext, nextStep, findAccountForm, loading, error }) {
  findAccountForm = findAccountForm || defaultFindAccountForm;
  const [translation] = useState(new TranslationService(pageContext));
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const { renderInput, isFormValid, touchForm, form } = useForm(translation, findAccountForm);

  const submit = (event: React.FormEvent) => {
    event.preventDefault();
    touchForm();
    setSubmitAttempted(true);
    return false;
  };

  useEffect(() => {
    if(submitAttempted) {
      if(isFormValid()) {
        nextStep(form);
      }

      setSubmitAttempted(false);
    }
  }, [submitAttempted]);

  return (
    <form className="form" onSubmit={submit}>
      <h1>{translation.translate('forgot_password.verify')}</h1>

      <p className="auth__description">{translation.translate('forgot_password.verify_description')}</p>

      <div className="form__group">
        <div className="form__field">
          {renderInput('username')}
          <InputDescription>{translation.translate('forgot_password.username_description')}</InputDescription>
        </div>
      </div>

      <div className="form__group">
        <div className="form__field">
          {renderInput('email')}
          <InputDescription>{translation.translate('forgot_password.email_description')}</InputDescription>
        </div>
      </div>

      {error ?
        <div className="form__error-message" dangerouslySetInnerHTML={{ __html: error }}></div> : null}

      <div className="form__group form__group--submit">
        <LargeButton type="submit" loading={loading}>
          <span>{translation.translate('forgot_password.next_step')}</span>
        </LargeButton>
      </div>

      <div className="form__group auth-link">
        <Link to={`${translation.linkPrefix}/login`}>{translation.translate('forgot_password.back')}</Link>
      </div>
    </form>
  );
}